import "./App.css";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";

import {
  Navbar,
  Header,
  Features,
  Overview,
  Program,
  Video,
  Faq,
  Subscribe,
  Footer,
} from "./components";

function App() {
  return (
    <>
      <header className="header-bg">
        <Navbar />
        <Header />
      </header>
      <ScrollToTop />
      <Features data-aos="fade-up" />
      <Overview />
      <Program />
      <Video />
      <Faq />
      <Subscribe />
      <Footer />
    </>
  );
}

export default App;
