import React, { useEffect } from "react";
import AOS from "aos";
import "./Overview.css";
import "aos/dist/aos.css";
import { BsBook } from "react-icons/bs";
import stepCollegesAIASImg from "../../assets/STEP-Colleges-AIAS.png";

const Overview = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="overview">
      <div className="container">
        <div className="u-title u-text">
          <BsBook color="orangered" size={30} />
          <h2>Overview</h2>
          <p>
            Unlock your engineering potential with the only course of it kind
            recognized by Engineers Australia.
          </p>
        </div>
        <hr className="outer-sep" />

        <div id="overview-aias-main" className="u-text u-text-light">
          <div id="overview-aias-left" data-aos="fade-right">
            <div id="aias">
              <p>
                The Advanced Diploma of Engineering course is specifically
                designed for overseas students and is the only course of its
                type recognized by Engineers Australia, the peak engineering
                body in Australia. It is taught at the Australian Institute of
                Advanced Studies (AIAS) in Brisbane and challenges students by
                providing them with a balance of practical skills and
                theoretical frameworks. Students will develop their knowledge
                and skills by solving real-world problems and using real-world
                methods and techniques, taught by experienced professionals from
                the engineering industry.
              </p>
              <p>
                Throughout the course, students will gain valuable experience
                working in teams, managing projects, using CAD software,
                analyzing structures and processes, and applying theoretical
                knowledge to real-world problems. Graduates will be equipped
                with the skills and experience needed to work in technical
                positions within engineering companies, and will also have the
                option to continue their studies. AIAS and Speedy Global will
                assist graduates in finding suitable sponsor employers to
                continue their engineering career in Australia. The curriculum
                includes learning about mathematics principles and techniques in
                engineering, materials science, thermodynamics, statics,
                dynamics, stress calculations, drafting, 2D and 3D CAD, project
                management, roles and responsibilities of professional
                engineers, and sustainability.
              </p>
              <p>
                The course aims to provide students with a useful and in-demand
                skillset, as well as the experience and confidence to manage the
                roles and responsibilities of an engineer. With a focus on
                sustainability, it helps students build a better world by
                providing them with the knowledge and skills to be part of the
                solutions needed for a sustainable, healthy, and prosperous
                society and environment.
              </p>
            </div>
          </div>
          <div id="overview-aias-right" data-aos="fade-left">
            <img
              src={stepCollegesAIASImg}
              alt="Australian Institute of Advanced Studies"
              aria-hidden="true"
            />
          </div>
        </div>
        <hr className="outer-sep" />
      </div>
    </section>
  );
};

export default Overview;
