import React, { useEffect } from "react";
import "./Program.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { CgOptions } from "react-icons/cg";

const Program = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="program">
      <div className="container u-text" data-aos="fade-up">
        <div className="u-title">
          <CgOptions color="orangered" size={30} />
          <h2>Program Course Outline</h2>
          <p>
            The following subjects have been hand picked to give the best
            possible outcome for our graduates.
          </p>
        </div>
        <div id="programContent">
          <div className="studyOptions">
            <h3>Core Subjects</h3>
            <table>
              <thead>
                <tr>
                  <th>Course Code</th>
                  <th>Course Title</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>MEM16006A</strong>
                  </td>
                  <td>Organise and Communicate Information</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM16008A</strong>
                  </td>
                  <td>Interact with Computing Technology</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM22001A</strong>
                  </td>
                  <td>Perform Engineering Activities</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM22002A</strong>
                  </td>
                  <td>Manage Self in the Engineering Environment</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM30007A</strong>
                  </td>
                  <td>Select Common Engineering Materials</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM30012A</strong>
                  </td>
                  <td>
                    Apply Mathematical Techniques in a Manufacturing,
                    Engineering or Related Environment
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>MSAENV272B</strong>
                  </td>
                  <td>
                    Participate in Environmentally Sustainable Work Practices
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="studyOptions">
            <h3>Electives</h3>
            <table className="studyList">
              <thead>
                <tr>
                  <th>Course Code</th>
                  <th>Course Title</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>MEM09002B</strong>
                  </td>
                  <td>Interpret Technical Drawing</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM12024A</strong>
                  </td>
                  <td>Perform Computations</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM30005A</strong>
                  </td>
                  <td>Calculate Force Systems Within Simple Beam Structures</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM30006A</strong>
                  </td>
                  <td>Calculate Stresses in Simple Structures</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM23063A</strong>
                  </td>
                  <td>Select and Test Mechanical Engineering Materials</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM30031A</strong>
                  </td>
                  <td>
                    Operate Computer Aided Design (CAD) Systems to Produce Basic
                    Drawing Elements
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM30032A</strong>
                  </td>
                  <td>Produce Basic Engineering Drawings</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM30033A</strong>
                  </td>
                  <td>
                    Use Computer Aided Design to Create and Display 3-D Models
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM09155A</strong>
                  </td>
                  <td>
                    Prepare Mechanical Models for Computer Aided Engineering
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM09157A</strong>
                  </td>
                  <td>Perform Mechanical Engineering Design Drafting</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM09204A</strong>
                  </td>
                  <td>Perform Basic Engineering Detail Drawing</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM12025A</strong>
                  </td>
                  <td>
                    Use Graphical Techniques and Perform Simple Statistical
                    Computations
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM14087A</strong>
                  </td>
                  <td>Apply Manufactured Product Design Techniques</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM22013A</strong>
                  </td>
                  <td>Coordinate Engineering Projects</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM23003A</strong>
                  </td>
                  <td>
                    Operate and Program Computers and/or Controllers in
                    Engineering Situations
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM23004A</strong>
                  </td>
                  <td>Apply Technical Mathematics</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM23006A</strong>
                  </td>
                  <td>
                    Apply Fluid and Thermodynamic Principles in Engineering
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM14089A</strong>
                  </td>
                  <td>
                    Integrate Mechanical Fundamentals in an Engineering Task
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM23109A</strong>
                  </td>
                  <td>Apply Mechanical Engineering Principles</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM23120A</strong>
                  </td>
                  <td>Select Mechanical Machine and Equipment Components</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM23123A</strong>
                  </td>
                  <td>Evaluate Manufacturing Processes</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM23007A</strong>
                  </td>
                  <td>Apply Calculus to Engineering Tasks</td>
                </tr>
                <tr>
                  <td>
                    <strong>MEM30025A</strong>
                  </td>
                  <td>Analyse a Simple Electrical System Circuit</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Program;
