import { SiFuturelearn } from "react-icons/si";
import { MdAttachMoney, MdSupportAgent } from "react-icons/md";
import { IoMdSchool } from "react-icons/io";

export const FeatureList = [
  {
    id: 1,
    icon: <IoMdSchool color="#0a1930" size={22} />,
    heading: "Internationally Recognised",
    text: "This is an Advanced Diploma of Engineering issued in Australia. It is fully recognised in Australia and Internationally.",
  },
  {
    id: 2,
    icon: <MdAttachMoney color="#0a1930" size={22} />,
    heading: "Full Australian Salary",
    text: "We will help you get work to support your study and you will be fully paid, just like an Australian, for all your work.",
  },
  {
    id: 3,
    icon: <MdSupportAgent color="#0a1930" size={22} />,
    heading: "Complete Support",
    text: "From the time you arrive we will be here for you. We make sure you are safe, have a place to live and get a good job. Just think of us 'Uncle'.",
  },
  {
    id: 4,
    icon: <SiFuturelearn color="#0a1930" size={22} />,
    heading: "Your Future Success",
    text: "After your study, if you want to stay in Australia, we will help you get work as and Engineer in Australia. We have a 100% placement rate from this program over several years.",
  },
];
