import React, { useEffect } from "react";
import AOS from "aos";
import "./Video.css";
import "aos/dist/aos.css";
import { HiOutlineDocument } from "react-icons/hi";
import YoutubeEmbed from "../UI/YoutubeEmbed/YoutubeEmbed";

const Video = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="video">
      <div className="container">
        <div className="u-title u-text">
          <HiOutlineDocument color="orangered" size={30} />
          <h2>Video</h2>
          <p>
            Speedy Global is proud to partner the Australian Institute of
            Advanced Studies (AIAS), one of the most respected colleges in
            Australia, to offer the fantastic STEP program.
          </p>
        </div>
        <div id="video-main" className="u-text u-text-dark">
          <div data-aos="fade-right">
            <div className="aias-video">
              <h3>Speedy Global - AIAS STEP Engineering Video</h3>
              <YoutubeEmbed embedId="Cz1h9uBiMM8" />
            </div>
          </div>
        </div>
        <div id="video-main" className="u-text u-text-dark">
          <div data-aos="fade-right">
            <div className="aias-video">
              <h3>Images from Engineering Program and the College</h3>
              <YoutubeEmbed embedId="DmN6wZWiNko" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Video;
