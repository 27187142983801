import React, { useEffect } from "react";
import AOS from "aos";
import Button from "../UI/Button/Button";
import { BsMouse } from "react-icons/bs";

import stepHeaderImg from "../../assets/STEP-Engineering.png";

import "./Header.css";
import "../UI/Button/Button.css";
import "aos/dist/aos.css";

const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="header">
      <div className="container">
        <div id="header-main" className="u-text u-text-light">
          <div id="header-left" data-aos="fade-right">
            <h1>
              Learn Engineering in Australia
              <div id="header-subtitle">
                <span>S</span>tudy <span>T</span>raining <span>E</span>
                mployment <span>P</span>lay
              </div>
            </h1>
            <p>
              The STEP Engineering program is an exclusive Australian program
              designed for overseas students who wish to gain a quality
              engineering education and have an enjoyable experience while
              studying in one of the best countries in the world for engineering
              education. The program is conducted in Brisbane, one of the most
              vibrant and livable cities in Australia. Through the STEP program,
              participants will earn money to help them pay for their studies
              and living expenses. After completing the STEP program,
              participants will have a world-class engineering education and a
              clear path to a successful future in engineering, with the added
              advantage of having studied in Australia, specifically in the city
              of Brisbane. All graduates of the program will, if they wish, be
              assisted to get an engineering job in so they can remain in
              Australia.
            </p>
            <h2>
              <i>
                <span>&quot;</span>Get a world class Engineering qualification
                and stay in Australia!<span>&quot;</span>
              </i>
            </h2>
            <div id="header-cta">
              <Button
                text={"Get Started"}
                btnClass={"btn-dark"}
                href={"#subscribe"}
              />
              <Button
                text={"Course Subjects"}
                btnClass={"btn-light"}
                href={"#options"}
              />
            </div>
          </div>
          <div id="header-right" data-aos="fade-left">
            <img
              src={stepHeaderImg}
              alt="STEP Engineering - General Image"
              aria-hidden="true"
            />
          </div>
        </div>
        <div id="floating-icon">
          <a href="#features">
            <BsMouse color="#fff" size={25} id="mouse" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Header;
